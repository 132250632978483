<template>
  <div class="login-container">
    <div>
      <video
        class="display-video"
        muted
        type="video/webm"
        src="../../assets/video/welcome.webm"
        autoplay
        loop
      ></video>
    </div>

    <div
      class="login_form"
      :style="{ position: 'fixed', top: '0px', left: '0px' }"
    >
      <div class="form_content">
        <el-form
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules"
          auto-complete="on"
          label-position="left"
        >
          <div class="title-container">
            <h3 class="title">{{ companyTitle }}</h3>
          </div>

          <el-form-item prop="username">
            <el-input
              v-model="loginForm.username"
              placeholder="请输入手机号"
              type="text"
            >
              <svg-icon slot="prefix" icon-class="user" />
            </el-input>
          </el-form-item>

          <el-form-item prop="password">
            <el-input
              v-model="loginForm.password"
              type="password"
              placeholder="请输入密码"
              @input="(e) => (loginForm.password = $replaceSymbols(e))"
            >
              <svg-icon slot="prefix" icon-class="pwd" />
            </el-input>
          </el-form-item>
          <!-- <nocaptcha lang="cn" :appkey="'FFFF0N00000000009B13'" :scene="`nc_login`" @callback="getToken" /> -->
          <el-form-item>
            <no-captcha @handleSlideAly="getToken" :loading="loading" />
          </el-form-item>
          <el-form-item class="tal">
            <el-checkbox v-model="remember"
              ><span style="color: #ffffff">记住手机号</span></el-checkbox
            >
            <span class="fr">
              <el-button type="text" @click="forgetPwd()">
                <span style="color: #ffffff; text-decoration: underline"
                  >忘记密码</span
                >
              </el-button>
            </span>
          </el-form-item>

          <el-button
            :loading="loading"
            type="primary"
            style="width: 100%; margin-bottom: 30px"
            @click.native.prevent="handleLogin"
            >登录</el-button
          >
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
// import { nocaptcha } from 'vue-nocaptcha'
import { getCode } from "@/api/userApi";
import { regexs } from "@/utils/validate";
import { removeToken } from "@/utils/auth";
import NoCaptcha from "@/components/NoCaptcha/index";
import { login } from "@/api/authApi";
import { getParameterByName } from "@/utils/index";

export default {
  name: "Login",
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
      },
      loginRules: {
        username: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: regexs.mobile,
            message: "请输入正确的手机号",
            trigger: "submit",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            pattern: regexs.pwd,
            message: "请输入8~15位字母和数字组成的密码",
            trigger: "submit",
          },
        ],
      },
      loading: false,
      redirect: undefined,
      isVerification: false,
      remember: false,
      companyTitle: "123",
    };
  },
  created() {
    const username = window.localStorage.getItem("userName");
    removeToken();
    if (username != null) {
      this.remember = true;
      this.loginForm.username = username;
    }
  },
  mounted() {
    window.addEventListener("keydown", this.keyDown);
    this.companyTitle = window.location.href.includes("customer")
      ? "奥立信工业互联网平台"
      : "爱景工业互联网平台";
      console.log('111');
      
  },
  components: {
    NoCaptcha,
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  methods: {
    keyDown(e) {
      //如果是回车则执行登录方法
      if (e.keyCode === 13) {
        this.handleLogin();
      }
    },
    handleLogin() {
      //   this.$router.push({name: 'HomePage'})
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          if (!this.isVerification) {
            this.$message.warning("请先滑动验证");
            return false;
          } else {
            this.loading = true;
            if (this.remember) {
              window.localStorage.setItem("userName", this.loginForm.username);
            } else {
              window.localStorage.removeItem("userName");
            }

            try {
              //service
              let service = getParameterByName("service");

              const { username, password } = this.loginForm;

              //17602526128
              //aaa123456

              //请求服务器
              const ticket = await login(username, password, service);

              //ticket
              console.log(ticket, "ticket");

              //重定向
              window.location.href = service + "&ticket=" + ticket;

              // await this.$store.dispatch("user/login", this.loginForm);
              // this.$router.push({ name: "Entry" });
              // window.localStorage.setItem("projectId", "");

              this.loading = false;
            } catch {
              this.loading = false;
              this.isVerification = false;
              // location.reload()
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getToken(val) {
      let params = {
        sessionId: val.sessionId,
        scene: val.scene,
        sig: val.sig,
        token: val.token,
      };
      getCode(params)
        .then(() => {
          this.isVerification = true;
        })
        .catch(() => {
          this.loading = false;
          //this.isVerification = false;
          this.isVerification = true;
          // location.reload()
        });
    },
    forgetPwd() {
      //service
      let service = getParameterByName("service");
      if (service) {
        //console.log(service,'service............');
        this.$router.push({
          path: "/forgetPwd",
          query: {
            service: service,
          },
        });
      } else {
        this.$router.push({
          path: "/forgetPwd",
        });
      }
    },
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDown, false);
  },
};
</script>


<style lang="scss">
.nc-container {
  #nc_1_wrapper {
    width: 100% !important;
  }
}
.nc-container .nc_scale span {
  height: 34px !important;
}
</style>

<style lang="scss">
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.display-video {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.login-container {
  min-height: 100%;
  width: 100%;
  //background: url('../../assets/image/login_bg.png') no-repeat center center;
  background-image: linear-gradient(to bottom right, #3a5fc0, #172649);
  background-size: 100% 100%;
  max-height: 100%;
  overflow: hidden;
  & > .login_form {
    width: 100%;
    min-height: 937px;
    text-align: right;
    padding-right: 130px;
    overflow-y: auto;
    .form_content {
      margin: 50px;
      margin-top: 200px;
      width: 400px;
      padding: 50px;
      display: inline-block;
      background: rgba(18, 31, 61, 0.8);
      border-radius: 5px;
      .el-form-item__content {
        .el-input__inner {
          background: #0e162b !important;
          border: 1px solid #1a2950 !important;
          color: #ffffff !important;
        }
        .el-input__prefix {
          left: 10px;
        }
      }
    }
  }

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }
  .el-checkbox__label {
    font-size: 12px !important;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #088eff !important;
    border-color: #088eff !important;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #088eff !important;
  }
  .tips {
    font-size: 14px;

    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}
</style>
